import Player from '@polydice/react-video-player';
import PropTypes from 'prop-types';
import { isMobile, isTablet } from 'utils';
// import useAds from './useAds';
import './style.scss';

function VideoPlayer({ coverUrl, videoUrl, recipeId, disableAds }) {
  // const adProp = useAds(recipeId, disableAds);
  const sources = [
    {
      src: videoUrl,
      type: 'application/x-mpegURL'
    }
  ];

  return (
    <Player
      sources={sources}
      poster={coverUrl}
      docking={isMobile() && !isTablet()}
      // {...adProp}
    />
  );
}

VideoPlayer.propTypes = {
  coverUrl: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
  recipeId: PropTypes.number.isRequired,
  disableAds: PropTypes.bool
};
VideoPlayer.defaultProps = {
  disableAds: false
};

export default VideoPlayer;
