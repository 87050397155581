export function isClient() {
  return !!(typeof window !== 'undefined' && window.document);
}

export function isMobile() {
  return /Mobi/i.test(navigator.userAgent);
}

export function isTablet() {
  return isMobile() && /Tablet|iPad/i.test(navigator.userAgent);
}

/**
 * About hybrid userAgent
 * @see https://www.notion.so/polydice/hybrid-Web-a87cd31e0ac2416d973d2fe49ca99d1d
 */
export const isICookApp = () => {
  if (!isMobile()) {
    return false;
  }

  const { userAgent } = navigator;
  const iOS = /iCook/i;
  const android = 'TBA';

  return iOS.test(userAgent) || userAgent === android;
};

export default {
  isClient,
  isMobile,
  isTablet,
  isICookApp
};
