import ReactOnRails from 'react-on-rails';
import BrowserDetector from '@polydice/ui/dist/BrowserDetector';
import SocialMediaShare from '../../assets/javascripts/reactjs/components/SocialMediaShare';
import VideoPlayer from '../../assets/javascripts/reactjs/components/VideoPlayer';

ReactOnRails.register({
  BrowserDetector,
  SocialMediaShare,
  VideoPlayer
});
